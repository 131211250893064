import * as React from "react";
import { navigate } from "gatsby-link";
import Layout from "../../components/Layout";

function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
}

export default class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isValidated: false };
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const form = e.target;
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": form.getAttribute("name"),
        ...this.state,
      }),
    })
      .then(() => navigate(form.getAttribute("action")))
      .catch((error) => alert(error));
  };

  render() {
    return (
      <Layout>
        <section className="section">
          <div className="container">
            <div className="content">
              <h1>お問い合わせ</h1>
              <div>
                <p>
                  ご質問・お問い合わせなどは下記連絡先よりお気軽にお問い合わせください。
                </p>
              </div>
              <div className="contact__area">
                <div className="contact__mail">
                  <h3>メールでのお問い合わせ</h3>
                  <p>
                    メールでのお問い合わせは下記メールアドレスへお願いします。
                  </p>
                  <div className="contact__text">
                    E-mail：
                    <a
                      className="contact__link"
                      href="mailto:info@monomarketing.jp?subject=ウェブサイトからのお問い合わせ"
                    >
                      info@monomarketing.jp
                    </a>
                  </div>
                  <div className="contact__notion">
                    <p className="contact__notion__title">ご注意</p>
                    <ul className="contact__notion__list">
                      <li className="contact__notion__list--item">
                        ※メールアドレスの記入ミスがある場合は返信ができませんので、お間違いないようご確認下さい。
                      </li>
                      <li className="contact__notion__list--item">
                        ※携帯メールアドレスよりお問い合わせの方は、お問い合わせのご返答にあたり、ドメイン指定受信をされているお客様は必ず以下のドメインをご登録ください。
                        ご登録が無い場合、当社からの返信メールをお受取り頂くことができませんのでご注意ください。
                      </li>
                    </ul>
                    <p>ドメイン：monomarketing.jp</p>
                  </div>
                </div>
                <div className="contact__tel">
                  <h3>電話でのお問い合わせ</h3>
                  <p>電話でのお問い合わせは下記番号へお願いします。</p>
                  <div className="contact__text">
                    TEL：
                    <a className="contact__link" href="tel:050-3699-6820">
                      050-3699-6820
                    </a>
                  </div>
                  <div className="contact__notion">
                    <p className="contact__notion__title">お問い合わせ時間</p>
                    <ul className="contact__notion__list">
                      <li className="contact__notion__list--item">
                        土日祝日を除く9:00~19:00
                      </li>
                      <li className="contact__notion__list--item">
                        ※電話番号をお確かめのうえ、おかけ間違いのないようご注意ください。
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    );
  }
}
